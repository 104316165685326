import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { DateInput, } from 'lp-components'
import { Field, getFormValues, } from 'redux-form'

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  formValues: PropTypes.object,
}

const defaultProps = {}

function FilterForm ({ handleChange, formValues, }) {
  useEffect(() => {
    handleChange(formValues || {})
  }, [formValues])

  return (
    <form noValidate>
      <Field
         name="startDateFrom"
         label={false}
         component={DateInput}
         placeholderText="Project Start Date From mm/dd/yyyy"
         className="col-2"
         autoComplete={false}
       />
      <Field
         name="startDateTo"
         label={false}
         component={DateInput}
         placeholderText="Project Start Date To mm/dd/yyyy" 
         className="col-2"
         autoComplete={false}
       />
    </form>
  )
}

FilterForm.propTypes = propTypes
FilterForm.defaultProps = defaultProps

function mapStateToProps(state){
  return {
    formValues: getFormValues('FilterForm')(state),
  }
}

export default compose(
  connect(mapStateToProps, null),
  lpForm({
    name: 'FilterForm',
    constraints: {},
}),
)(FilterForm)