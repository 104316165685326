import React from 'react'
import { PieChart, Pie, Legend, Cell, } from 'recharts'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { map, intersection, } from 'lodash'
import { COLORS, RADIAN, } from 'config'

const propTypes = {
  projects: PropTypes.array,
  rawData: PropTypes.array,
}

const defaultProps = {}

function ArchitectureOverview ({ rawData, projects, }) {
  const mappedProjects = projects.map(({ id }) => id)

  const data = map(rawData, ({ name, projects }) => {
    return {
      name,
      value: intersection(projects || [], mappedProjects).length
    }
  })

  const percentage = (value) => projects?.length ? (value / projects?.length * 100) : 0

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${percentage(value).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <div className="col-6">
      <h2>Architecture Overview</h2>
      <PieChart width={600} height={600}>
        <Legend formatter={(name, { payload: { value } }) => {
          return `${name} (${percentage(value).toFixed(0)}%)`
        }} />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}

ArchitectureOverview.propTypes = exact(propTypes)
ArchitectureOverview.defaultProps = defaultProps

export default React.memo(ArchitectureOverview)