import { createRequest } from 'lp-redux-api'

function fetchTableRecords(tableName, options = {}) {
  return {
    url: `/${tableName}`,
    query: options,
  }
}

// function fetchTableRecord(tableName, tableRecordId) {
//   return {
//     url: `/${tableName}/${tableRecordId}`,
//     // query: apiKeyParams,
//     onSuccess: (resp) => {
//       return resp
//     },
//   }
// }

export const fetchProjects = createRequest('FETCH_PROJECTS', () => {
  return fetchTableRecords('projects')
})

export const fetchLibraries = createRequest('FETCH_LIBRARIES', () => {
  return fetchTableRecords('libraries')
})

export const fetchTechnologies = createRequest('FETCH_TECHNOLOGIES', () => {
  return fetchTableRecords('technologies')
})

export const fetchArchitectures = createRequest('FETCH_ARCHITECTURES', () => {
  return fetchTableRecords('architectures')
})

// Projects = All ladder table records ?
// export const fetchLadder = createRequest('FETCH_LADDER', (ladderTableName) => {
//   return fetchTableRecords('ladders', { view: ladderTableName })
// })

// export const fetchLadderLevel = createRequest(
//   'FETCH_LADDER_LEVEL',
//   (ladderTableName, id) => {
//     return fetchTableRecord(ladderTableName, id)
//   }
// )

// export const fetchLevels = createRequest('FETCH_LEVELS', () => {
//   return fetchTableRecords('levels')
// })

// export const fetchLevel = createRequest('FETCH_LEVEL', (id) => {
//   return fetchTableRecord('levels', id)
// })

// export const fetchAssignments = createRequest(
//   'FETCH_ASSIGNMENTS',
//   ({ level, ladder }) => {
//     return fetchTableRecords('assignments', {
//       view: `${ladder} - All (Level - ${level})`,
//     })
//   }
// )

// export const fetchAssignment = createRequest('FETCH_ASSIGNMENT', (id) => {
//   return fetchTableRecord('assignments', id)
// })

// export const fetchSkills = createRequest('FETCH_SKILLS', () => {
//   return fetchTableRecords('skills')
// })

// export const fetchSkill = createRequest('FETCH_SKILL', (id) => {
//   return fetchTableRecord('skill', id)
// })

// export const fetchSkillLevels = createRequest('FETCH_SKILL_LEVELS', () => {
//   return fetchTableRecords('skill levels')
// })

// export const fetchSkillLevel = createRequest('FETCH_SKILL_LEVEL', (id) => {
//   return fetchTableRecord('skill levels', id)
// })

// export const fetchProjectRoles = createRequest('FETCH_PROJECT_ROLES', () => {
//   return fetchTableRecords('project roles')
// })

// export const fetchProjectRole = createRequest('FETCH_PROJECT_ROLE', (id) => {
//   return fetchTableRecord('project roles', id)
// })

// export const fetchTitles = createRequest('FETCH_TITLES', (ladderTableName) => {
//   return fetchTableRecords('titles', { view: ladderTableName })
// })