import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Bar,
  Legend,
  // Line,
} from 'recharts'
import { first, range, map, intersection, } from 'lodash'

function getQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1);
}

const propTypes = {}

const defaultProps = {}

function InstallationOverview ({ currentLibrary, libraries, projects, }) {
  const currentLibraryData = first(libraries.filter(({ name }) => currentLibrary === name))

  let data = {}

  range(2016, new Date().getFullYear() + 1).forEach((year) => {
    data[`${year}.1`] = { installCount: 0, projectCount: 0, }
    data[`${year}.2`] = { installCount: 0, projectCount: 0, }
    data[`${year}.3`] = { installCount: 0, projectCount: 0, }
    data[`${year}.4`] = { installCount: 0, projectCount: 0, }
  });

  const filteredProjects = projects.filter(({ fields: { startDate }}) => startDate);

  (currentLibraryData['project(fromInstallations)'] || []).forEach((projectId) => {
    filteredProjects.forEach(({ id, fields: { startDate }, }) => {
      const startDateTime = new Date(startDate)
      const key = `${startDateTime.getFullYear()}.${getQuarter(startDateTime)}`
      if (id === projectId) data[key].installCount += 1
    })
  })

  filteredProjects.forEach(({ fields: { startDate, dependencies, }, }) => {
    const startDateTime = new Date(startDate)
    const key = `${startDateTime.getFullYear()}.${getQuarter(startDateTime)}`
    if (intersection(currentLibraryData.dependencies, dependencies).length > 0) data[key].projectCount += 1
  })

  let xAxisTicks = []

  const mappedData = map(data, (counts, date) => { 
    const [year, quarter] = date.split('.')
    const formattedDate = `Q${quarter} - ${year}`
    xAxisTicks.push(formattedDate)
    return { installationDate: formattedDate, ...counts, }
  })

  return (
    <div className='col-6'>
      <ComposedChart
          width={600}
          height={400}
          data={mappedData}
          margin={{
            top: 5,
            right: 20,
            bottom: 20,
            left: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis padding={{ bottom: 10 }} dataKey="installationDate" label={{ value: 'Date', position: "insideBottom" }} ticks={xAxisTicks} />
          <YAxis dataKey="installCount" minTickGap={1} label={{ angle: -90, value: 'Installations' }} ticks={range(0, 8)} />
          <Tooltip />
          <Legend />
          <Bar dataKey="installCount" fill="#8884d8" />
          <Bar dataKey="projectCount" fill="#82ca9d" />
          {/* <Line type="monotone" dataKey="installCount" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
        </ComposedChart>
    </div>
  )
}

InstallationOverview.propTypes = exact(propTypes)
InstallationOverview.defaultProps = defaultProps

export default React.memo(InstallationOverview)