// App-wide config (routes, env vars) goes here.

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000']
export const RADIAN = Math.PI / 180

export const LIBRARY_STATUSES = {
  DEPLOYED: 'Deployed',
  PROPOSED: 'Proposed',
}