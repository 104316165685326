import React, { useEffect, useState, } from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
// import { selectors } from '../reducer'
// import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { sumBy, intersection, sum, uniq, flatten, round, } from 'lodash'
import { SortableTable, TableColumn, } from 'lp-components'
import { ArchitectureOverview, TechnologyOverview, InstallationOverview, } from '../views'
import { LIBRARY_STATUSES } from 'config'
import { FilterForm } from '../forms'

const propTypes = {
  fetchLibraries: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchTechnologies: PropTypes.func.isRequired,
  fetchArchitectures: PropTypes.func.isRequired,
}

const defaultProps = {}

const valueToPercentage = (value) => {
  if (value) return round(value * 100, 2)
}

const roundedValue = (value) => {
  if (value) return round(value, 2)
}

function Home({
  fetchProjects,
  fetchLibraries,
  fetchTechnologies,
  fetchArchitectures,
}) {
  const [libraries, setLibraries] = useState([])
  const [technologies, setTechnologies] = useState([])
  const [technologyFilters, setTechnologyFilters] = useState([])
  const [statuses, setStatuses] = useState([])
  const [allLibraries, setAllLibraries] = useState([])
  const [architectures, setArchitectures] = useState([])
  const [projects, setProjects]= useState([])
  const [allProjects, setAllProjects] = useState([])
  const [startDateFromFilterValue, setStartDateFromFilterValue] = useState()
  const [startDateToFilterValue, setStartDateToFilterValue] = useState()
  const [viewingLibraryDetail, setViewingLibraryDetail] = useState(false)
  const [currentLibrary, setCurrentLibrary] = useState()

  function LibrarySelectionCell({ value, }){
    // key, name, value and data
    return (
      <td>
        <span
          onClick={() => {
            if (value !== currentLibrary){
              setViewingLibraryDetail(true)
              setCurrentLibrary(value)
            } else {
              setViewingLibraryDetail(false)
              setCurrentLibrary(null)
            }
          }}
        >
          {value}
        </span>
      </td>
    )
  }

  const mapLibraryData = ({ data, projects, }) => {
    const totalHours = sumBy(projects, ({ fields: { hours } }) => hours || 0)

    const libraryData = data.map((library) => {
      let totalAffectedProjectHours = 0
      let totalAffectedProjects = 0
      let localRealization = 0
      let globalRealization = 0
      let totalInstallations = 0
  
      const totalHoursByDependency = sum(projects.map(({ fields: { dependencies, hours }}) => {
        if (intersection(library.fields.dependencies, dependencies).length > 0){
          totalAffectedProjects +=1
          return hours
        }
        return 0
      }))
  
      const libraryProjectInstallations = library.fields['project(fromInstallations)'] || []
  
      libraryProjectInstallations.forEach((projectId) => {
        projects.forEach(({ id, fields: { hours }, }) => {
          if (id === projectId){
            totalAffectedProjectHours += (hours || 0)
            totalInstallations += 1
          }
        })
      })
      const relevance = totalHoursByDependency / totalHours
  
      if (LIBRARY_STATUSES.PROPOSED === library.fields.status){
        localRealization = 1
        globalRealization = totalHoursByDependency / totalHours
        totalInstallations = totalAffectedProjects
      } else {
        localRealization = totalHoursByDependency ? totalAffectedProjectHours / totalHoursByDependency : 0
        globalRealization = totalAffectedProjectHours / totalHours
      }
      const impact = totalInstallations * library.fields.efficiency
      const roi = library.fields.investment ? (impact / library.fields.investment) : 0
      return {
        ...library.fields,
        installationCount: totalInstallations,
        relevance,
        localRealization,
        globalRealization,
        impact,
        roi,
      }
    })
    setStatuses(uniq(libraryData.map(({ status }) => status)).map((status) => { return { name: status, value: status, }}))
    setLibraries(libraryData)
  }

  const filterResults = ({ value, filterKey, }) => {
    if (!value) return mapLibraryData({ data: allLibraries, projects, })
    const libraryData = allLibraries.filter((library) => {
      const { fields } = library
      const values = flatten([(fields[filterKey] || [])])
      return values.indexOf(value) > -1
    })
    mapLibraryData({ data: libraryData, projects, })
  }

  const filterResultsByDateRange = (filter) => {
    setStartDateToFilterValue(filter.startDateTo)
    setStartDateFromFilterValue(filter.startDateFrom)
  }

  useEffect(() => {
    const filteredProjects = allProjects.filter(({ fields: { startDate, } }) => {
      const afterStartDate = !startDateFromFilterValue || (startDate && new Date(startDate) >= startDateFromFilterValue)
      const beforeEndDate = !startDateToFilterValue || (startDate && new Date(startDate) <= startDateToFilterValue)
      return afterStartDate && beforeEndDate
    })
    setProjects(filteredProjects)
    mapLibraryData({ data: allLibraries, projects: filteredProjects, })
  }, [startDateToFilterValue, startDateFromFilterValue])

  useEffect(async () => {
    const projects = await fetchProjects()
    setProjects(projects)
    setAllProjects(projects)
    
    fetchTechnologies().then((data) => {
      setTechnologies(data.map(({ fields }) => fields))
      setTechnologyFilters(data.map(({ fields: { name }}) => { return { name: name, value: name } }))
    })

    fetchArchitectures().then((data) => setArchitectures(data.map(({ fields}) => fields)))

    fetchLibraries().then((data) => {
      mapLibraryData({ data, projects, })
      setAllLibraries(data)
    })
  }, [])

  const containerClassName = viewingLibraryDetail ? 'col-6' : 'col-12'

  return (
    <div>
      <h1>Dashboard</h1>
      <p>
        A quick overview of libraries built by OpEx.
      </p>
      <div className="row">
        <span className="col-1">Filters:</span>
        {!!technologyFilters.length && 
          <>
            <select className="col-2" name="dependencyFilter" onChange={(e) => filterResults({ value: e.target.value, filterKey: 'dependencyNames'})}>
              <option value="">--- All Technologies ---</option>
              {technologyFilters.map(({ name, value,}) => <option key={name} value={value}>{name}</option>)}
            </select>
            <select className="col-2" name="statusFilter" onChange={(e) => filterResults({ value: e.target.value, filterKey: 'status'})}>
              <option value="">--- All Statuses ---</option>
              {statuses.map(({ name, value,}) => <option key={name} value={value}>{name}</option>)}
            </select>
            <FilterForm
              handleChange={filterResultsByDateRange}
            />
          </>}
      </div>
      <div className="row">
        {!!libraries.length &&
          <>
            <SortableTable data={libraries} className={containerClassName}>
              <TableColumn name="name" component={LibrarySelectionCell} />
              <TableColumn name="status" />
              <TableColumn name="installationCount" />
              <TableColumn name="dependencyNames" label="Technologies" />
              <TableColumn name="relevance" label="Relevance (%)" format={valueToPercentage} />
              <TableColumn name="localRealization" label="Local Realization (%)" format={valueToPercentage} />
              <TableColumn name="globalRealization" label="Global Realization (%)" format={valueToPercentage} />
              <TableColumn name="impact" label="Impact (hrs)" />
              <TableColumn name="roi" label="ROI" format={roundedValue} />
            </SortableTable>
            {viewingLibraryDetail && <InstallationOverview currentLibrary={currentLibrary} projects={projects} libraries={libraries} />}
          </>
        }
      </div>
      <div className="row">
        <div className={containerClassName}>
          {!!architectures.length && <ArchitectureOverview rawData={architectures} projects={projects} />}
          {!!technologies.length && <TechnologyOverview rawData={technologies} projects={projects} />}  
        </div>
      </div>
    </div>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  fetchProjects: apiActions.fetchProjects,
  fetchLibraries: apiActions.fetchLibraries,
  fetchTechnologies: apiActions.fetchTechnologies,
  fetchArchitectures: apiActions.fetchArchitectures,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home)
